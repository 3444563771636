/**
 * Candidate information form.
 * Supported props: sitedata fields onSubmit isQualified [errortheme]
 */

import React, { useState, useRef } from 'react';
import InputMask from 'react-input-mask';
import Dropdown from './Dropdown';
import PatientFields from './PatientFields';
import { getCookie, css } from '../util/pagetools';
import validator from '../util/validationManager';

// Create a forward ref to enable accessing ref for focusing
const PhoneInput = React.forwardRef((props, ref) => (
  <InputMask
    mask="(999) 999-9999"
    name={props.name}
    id={props.name}
    value={props.value}
    className="form-control"
    aria-required="true"
    ref={ref}
    onChange={props.onChange}
    onBlur={props.onChange}>
  </InputMask>
));

const reqIndicator = <span title="This field is required.">*</span>;
if (typeof window !== 'undefined') {
  (window._gmcb = () => { });
}
const UTM = 'UTM_INFO';
let formInteracted = false;

export default function PatientForm(props) {
  const site = props.sitedata;
  const formTheme = `patient-form${props.isQualified?'':' dq'}`;
  const formAction = props.isQualified ? props.fields.cta : props.fields.ctaDq;
  const errorTheme = props.errortheme || 'text-red';
  const vm = validator();
  // console.log('validator?', vm);
  const [patientInfo, setPatientInfo] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    howDidYouHearAboutUs: '',
    qualified: (props.isQualified ? 1 : 0),
    Language: site.locale
  });

  const [errors, setErrors] = useState({
    firstname: true,
    lastname: true,
    phone: true,
    email: true,
  });

  vm.addValidation('firstname', /(.|\s)*\S(.|\s)*/, useRef(null));
  vm.addValidation('lastname',  /(.|\s)*\S(.|\s)*/, useRef(null));
  vm.addValidation('phone', /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, useRef());
  vm.addValidation('email', /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/, useRef(null));

  const extraFields = [
    <Dropdown name="howDidYouHearAboutUs" items={props.fields.referredby} defaultItem={props.fields.referredbyDefault} theme="dropdown form-control" onChangeBlur={handleInputChange} />
  ];

  function onSubmit(e) {
    e.preventDefault();
    formInteracted = true;
    // Validate here!
    const inValidFields = validateForm();
    if (inValidFields.length>0) {
      // Focus on first invalid field
      const firstError = vm.getRef(inValidFields[0]).current;
      if (firstError.focus) firstError.focus();
      else if (firstError.getInputDOMNode) firstError.getInputDOMNode().focus();
      return;
    }
    const payload = Object.assign({
      utm: getCookie(UTM),
      site: site.siteurl
    }, patientInfo)
    if (props.onSubmit) {
      props.onSubmit(payload);
      formInteracted = false; // reset interaction flag
    }
  }

  function handleInputChange(event) {
    setPatientInfo({
      ...patientInfo,
      [event.target.name]: event.target.value
    });
  }

  function validateInput(event) {
    setPatientInfo({
      ...patientInfo,
      [event.target.name]: event.target.value
    });
    const validator = vm.getRule(event.target.name);
    const isFieldOk = (validator ? validator.test(event.target.value) : true);
    setErrors({ ...errors, [event.target.name]: !isFieldOk });
    // console.log(`Errors at field ${event.target.name} ->`, errors);
  }

  function showError(field) { return (errors[field] && formInteracted); }

  function validateForm() {
    // Collect an array of field names and their statuses
    const updatedErrors = vm.fields().reduce((status, field) => {
      const fieldvalue = patientInfo[field];
      const validator = vm.getRule(field);
      const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
      // console.log(`VF: ${field} = "${fieldvalue}" ..`, isFieldOk);
      status[field] = !isFieldOk;
      return status;
      }, {});
    setErrors(updatedErrors);
    // Return only those items with errors
    return Object.keys(updatedErrors).filter(key => updatedErrors[key]);
  }

  return (
    <form className={formTheme}>
      <div className="row">
        <div className="col-sm-6 form-group">
          <label htmlFor="firstname">{props.fields.firstname} {reqIndicator}</label>
          <input type="text" name="firstname" id="firstname" className="form-control" aria-required="true"
                 onChange={validateInput} onBlur={validateInput} value={patientInfo.firstname} ref={vm.getRef('firstname')} />
          <p className={`${errorTheme} ${showError('firstname') ? 'vis' : 'hid'}`}>First name must not be blank</p>
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="lastname">{props.fields.lastname} {reqIndicator}</label>
          <input type="text" name="lastname" id="lastname" className="form-control" aria-required="true"
                 onChange={validateInput} onBlur={validateInput}value={patientInfo.lastname} ref={vm.getRef('lastname')} />
          <p className={`${errorTheme} ${showError('lastname') ? 'vis' : 'hid'}`}>Last name must not be blank</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 form-group">
          <label htmlFor="phone">{props.fields.phone} {reqIndicator}</label>
          <PhoneInput name="phone" onChange={validateInput} value={patientInfo.phone} ref={vm.getRef('phone')} />
          <p className={`${errorTheme} ${showError('phone') ? 'vis' : 'hid'}`}>Please enter a valid phone number</p>
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="email">{props.fields.email} {reqIndicator}</label>
          <input type="text" name="email" id="email" className="form-control" aria-required="true"
                 onChange={validateInput} value={patientInfo.email} ref={vm.getRef('email')}/>
          <p className={`${errorTheme} ${showError('email') ? 'vis' : 'hid'}`}>Please enter a valid email</p>
        </div>
      </div>
      <PatientFields fields={extraFields} />
      <div className="btn-container">
        <button className={css('std-btn', props.fields.btntheme)} onClick={onSubmit}>{formAction}</button>
      </div>
    </form>
  );
};
