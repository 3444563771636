/* Custom React hook to facilitate use of CookieBanner */

import { useState, useEffect } from 'react';
import CookieConsenter from '../util/cookietools';

export default function useCookieBanner(isEnabled) {
  const consenter = CookieConsenter();
  const [show, setShow] = useState(false);
  const [consented, setConsented] = useState(consenter.COOKIES_NOT_SET);

  function display(e) { setShow(true); }
  function hide(e) { setShow(false); }

  function doDisplay() { return (show || (consented===consenter.COOKIES_NOT_SET)); }

  function confirm() {
    setConsented(consenter.COOKIES_CONFIRMED);
    consenter.confirm();
    setShow(false);
  }

  function decline() {
    setConsented(consenter.COOKIES_DECLINED);
    consenter.decline();
    setShow(false);
  }

  useEffect(() => {
    setConsented(consenter.getConsent());
  }, [consenter]);

  return {display, hide, doDisplay, confirm, decline};
};