/* Dropdown implements an HTML select component
   Supported props: name items defaultItem theme onChangeBlur focusRef errorFn errorMessage value
*/

import React from 'react';

function defaultHandler(e) { console.log(`-> ${e.target.value}`); }

export default function Dropdown(props) {
  const handler = props.onChangeBlur || defaultHandler;
  const attribs = {
    name: props.name,
    onChange: handler,
    onBlur: handler
  };
  if (props.theme) attribs.className = props.theme;
  if (props.focusRef) attribs.ref = props.focusRef;
  if (props.value) attribs.value = props.value;

  if (props.errorFn) {
    return (
      <>
        <select {...attribs}>
          {props.defaultItem && <option value="">{props.defaultItem}</option>}
          {fillOptions(props.items)}
        </select>
        <p className={`text-red ${props.errorFn(props.name) ? 'vis' : 'hid'}`}>{props.errorMessage || 'This field is required'}</p>
      </>
    );
  }
  return (
    <select {...attribs}>
      {props.defaultItem && <option value="">{props.defaultItem}</option>}
      {fillOptions(props.items)}
    </select>
  );
};

function fillOptions(items) {
  if (!Array.isArray(items) || items.length===0) return [<option key="opt_0">No items</option>];
  if ((typeof items[0])!=='object') {
    return items.map((item, index) => { return <option key={`opt_${index}`}>{item}</option>; });
  }
  return items.map((item, index) => {
    // Render options from array of [{id,value}]
    if (item.hasOwnProperty('id')) {
      return <option key={`opt_${index}`} value={item.id}>{item.value}</option>;
    }
    return <option key={`opt_${index}`}>{item.value}</option>;
  });
}
