/**
 * PatientFields renders extra fields for patient form into 2 columns.
 * Supported props: fields
 */
import React from 'react';

export default function PatientFields(props) {
  const comps = props.fields.map((comp, idx) => <div key={`f_${idx}`} className="col-sm-6 form-group">{comp}</div>);
  return <>{wrapMarkup(comps, 2)}</>;
};

function wrapMarkup(group, breakAfter) {
  const leftOvers = group.length%breakAfter;
  const newgroup = group.reduce((orig, curr, idx) => {
    if (idx%breakAfter===0) {
      orig.push(curr);
    } else {
      alterArr(orig, curr, idx);
    }
    return orig;
    }, []);
  if (leftOvers>0) alterArr(newgroup);
  return newgroup;
}

function alterArr(arr, current, index) {
  const lastitem = arr.pop();
  if (current) {
    arr.push(<div key={`r_${index}`} className="row">{lastitem}{current}</div>);
  } else {
    arr.push(<div key="r_last" className="row">{lastitem}</div>);
  }
}
