/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '../util/pagetools';

export default function Banner(props) {
  const cfg = props.src;

  return (
    <div id="banner" className={css('banner-container', cfg.banner.theme)}>
      <div className="flx-1 flx-v-center sp-y-30 banner-left banner-left-texture">
        <h1 className="banner-title">{cfg.title}</h1>
        <div className="banner-text">{cfg.subtitle}</div>
        <Link to="#screener" className={css('std-btn w-200', cfg.banner.btntheme)}>{cfg.startstudy}</Link>
      </div>
      <div>
        <StaticImage src='../images/banner.jpg' alt="main banner" layout="fullWidth" className="hidden-xs" />
        <StaticImage src='../images/banner_mobile.jpg' alt="main banner" layout="fullWidth" className="visible-xs" />
      </div>
    </div>
  );
};
