/**
 * FB and GA tracking functions.
 */
const isBrowser = (typeof window !== 'undefined');

/**
 * Send data to Facebook.
 * @param boolean - is the candidate qualified
 */
function sendToFB(isQualified) {
  if (isBrowser) {
    if (window.fbq) window.fbq('trackCustom', 'PreScreenerFinish', {status: (isQualified ? 'PD' : 'DQ')});
    if (isQualified) {
      if (window.gtag) window.gtag('event', 'conversion', {'send_to': 'AW-951169675/hOBJCNyolaMYEIvlxsUD'});
      if (window.ttq) window.ttq.track('PSP');
      if (window.snaptr) {
        window.snaptr('track', 'CUSTOM_EVENT_1', {'uuid_c1': 'INSERT_UUID_C1', 'success': 1});
        window.snaptr('track', 'SIGN_UP');
        /*
        window.snaptr('init', '6259176b-18d7-4763-9274-ec5853577a7d', {'uuid_c1': 'INSERT_UUID_C1', 'success': 1, user_email: 'test@zyx.com'});
        */
      }
    }
  }
}

/**
 * Send data to Google Analytics for an individual screener question.
 * @param int - the index of the slide (zero-based)
 * @param boolean - whether or not candidate answered "Yes"
 */
function sendToGA(index, isAffirmative) {
  if (!isBrowser) return;
  const stage = (index===0 ? 'screener_start' : 'screener_progress');
  const evLabel = 'Q'+(index+1)+(isAffirmative?'Y':'N');
  if (window.gtag) {
    window.gtag('event', stage, { 'event_category': 'Screener', 'event_label': evLabel });
  }
}

/**
 * Send data to Google Analytics for screener conclusion.
 * @param boolean - is the candidate qualified
 */
function sendToGA_done(isQualified) {
  if (isBrowser && window.gtag) {
    window.gtag('event', 'screener_finish', { 'event_category': 'Screener', 'event_label': `Screener finished ${(isQualified ? 'Q' : 'DQ')}` });
  }
}

export { sendToFB, sendToGA, sendToGA_done };
