/**
 * A Screener is a container for Slides with logic for conducting a survey.
 * Supported props: config, sitedata, localizerfn
 */
import React from 'react';
import { navigate } from 'gatsby';
import Slide from './Slide';
import PatientForm from './PatientForm';
import addScreenerLogic from '../util/screenerNav';
import { adjustSlideHeight } from '../util/pagetools';
import { sendToFB, sendToGA, sendToGA_done } from '../util/gatools';
import Safe from './Safe';

class Screener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlideIndex: 0,
      visitedSlideIndex: 0,
      isQualified: false,
      patientData: {}
    };
    this.isBrowser = (typeof window !== 'undefined');
    this.cfg = addScreenerLogic(props.config);
    this.advancer = this.advancer.bind(this);
    this.collector = this.collector.bind(this);
    this.getPatientLocation = this.getPatientLocation.bind(this);
    this.endpoint = '/.netlify/functions/screenerResults';
  }

  componentDidMount() {
    const callback = adjustSlideHeight;
    function watchResize() { window.addEventListener('resize', callback); }
    watchResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', adjustSlideHeight);
  }

  /**
   * High order function to provide an event handler
   * @param object - a question configuration object
   * @param boolean - a boolean user response
   * @return function
   */
  advancer(question, slideReply) {
    const screener = this;
    return function(event) { screener.handleScreenerAction(question, slideReply); };
  }

  /**
   * Changes active slide based on user input.
   * @param object - a question configuration object
   * @param boolean - a boolean user response
   */
  handleScreenerAction(question, slideReply) {
    const currCfg = this.cfg;
    const canProceed = question.proceed(slideReply);
    sendToGA(question.id, slideReply);
    if (canProceed) {
      this.nextSlide(this.state.activeSlideIndex+1);
      if (question.seq>=currCfg.lastQuestionIndex) {
        sendToFB(true);
        sendToGA_done(true);
        this.setState({isQualified: true});
      }
    } else {
      // Disqualification is indicated by setting activeSlideIndex to index of the fail page
      sendToFB(false);
      sendToGA_done(false);
      this.nextSlide(currCfg.failPageIndex);
    }
  }

  /**
   * Convenience function to switch to a given slide by its index.
   * @param int - the index of a slide
   * @return none
   */
  nextSlide(slideIndex) {
    this.setState({activeSlideIndex: slideIndex});
    this.setState({visitedSlideIndex: slideIndex});
  }

  /**
   * Sets the patient data from a form.
   * @param object - field values from a form
   */
  collector(obj) {
    this.addPatientData(obj);
    this.sendPatientData(obj);
    this.navigateToConclusion(this.state.isQualified);
  }

  /**
   * Send patient information to a specified endpoint.
   * @param object - the patient information
   */
  sendPatientData(payload) {
    if (!this.endpoint) {
      console.log('Currently not sending to backend. Payload=', payload);
      return;
    }
    fetch(this.endpoint, {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify(payload)
    }).then(resp => {
      return resp.json();
    }).catch(error => {
      navigate(this.props.localizerfn('/generalError/'), {state: {description: error.message}});
    });
  }

  /**
   * Convenience method to add one or more fields to the patient data.
   * @param object - fields to append to or overwrite patient data
   */
  addPatientData(obj) { this.setState({patientData: Object.assign(this.state.patientData, obj)}); }

  /**
   * Navigate to the conclusion page specifying qualification of candidate.
   * @param boolean - is the candidate qualified
   */
  navigateToConclusion(isQualified) {
    const {sitename, siteurl, homelink, footer, sections} = this.props.sitedata;
    const pageProps = {
      header: '',
      text: [''],
      isQualified: isQualified,
      sitedata: {
        sitename,
        siteurl,
        homelink,
        footer,
        sections: sections.map(sec => ({title:sec.title, linkto:sec.linkto}))
      }
    }
    if (isQualified) {
      const success = this.cfg.successResult.thankyou.locationpicked;
      pageProps.header = success.header;
      pageProps.text = success.text;
      pageProps.theme = success.theme;
      pageProps.btntheme = success.btntheme;
    } else {
      const fail = this.cfg.failResult.thankyou;
      pageProps.header = fail.header;
      pageProps.text = fail.text;
      pageProps.theme = fail.theme;
      pageProps.btntheme = fail.btntheme;
    }
    navigate(this.props.localizerfn('/thankyou/'), {state: pageProps});
  }

  /**
   * Retrieve patient location from the state.
   * @return object
   */
  getPatientLocation() {
    return { lat: this.state.patientData.lat, lng: this.state.patientData.lng, country: this.state.patientData.country };
  }

  render() {
    const currCfg = this.cfg;
    if (!currCfg || Object.keys(currCfg).length===0) { return <section id="screener"></section>; }
    const patientSiteInfo = {
      locale: this.props.sitedata.locale,
      siteurl: this.props.sitedata.siteurl
    };
    const questions = currCfg.questions;
    const btnLabels = {
      affirm: currCfg.questionLabel.affirm,
      deny: currCfg.questionLabel.deny
    };
    return (
      <section id="screener" className="screener-section">
        <div className="screener">
          {questions.map((item,idx) => (
            <Slide key={`q-${idx}`}
                   question={item}
                   btnLabels={btnLabels}
                   btnTheme={currCfg.btntheme}
                   isActive={this.state.activeSlideIndex===item.seq}
                   isPast={this.state.visitedSlideIndex>=item.seq}
                   responder={this.advancer}
            />
          ))}
          <Slide key="success-page"
                 idx={currCfg.successPageIndex}
                 isActive={(this.state.activeSlideIndex===currCfg.successPageIndex)}
                 isPast={this.state.visitedSlideIndex>=currCfg.successPageIndex}
                 theme={currCfg.successResult.theme}>
            <Safe type="h1" className="slide-h1" content={currCfg.successResult.header} />
            <Safe type="h2" className="slide-h2" content={currCfg.successResult.message} />
            {this.state.isQualified &&
              <PatientForm onSubmit={this.collector}
                           theme={currCfg.successResult.theme}
                           sitedata={patientSiteInfo}
                           fields={this.props.sitedata.patientFields}
                           isQualified={true} />
            }
          </Slide>
          <Slide key="fail-page"
                 idx={currCfg.failPageIndex}
                 isActive={(this.state.activeSlideIndex===currCfg.failPageIndex)}
                 theme={currCfg.failResult.theme}>
            <Safe type="h1" className="slide-h1" content={currCfg.failResult.header} />
            <Safe type="p" className="slide-h2" content={currCfg.failResult.message} />
            {!this.state.isQualified &&
              <PatientForm onSubmit={this.collector}
                           theme={currCfg.failResult.theme}
                           sitedata={patientSiteInfo}
                           fields={this.props.sitedata.patientFields}
                           isQualified={false} />
            }
          </Slide>
        </div>
      </section>
    );
  }
}
export default Screener;
